<template>
  <div
    v-if="show"
    ref="edit-menu-modal"
    class="modal modal__right"
    :class="{ show: show === true }"
    :style="{ display: 'block' }"
    id="addModal"
    tabindex=""
    role="dialog"
    aria-labelledby="modalLabel"
    aria-hidden="true"
  >
    <!--CONFIRM DIALOG-->
    <div
      class="modal modal__confirm show"
      id="confirmModal"
      tabindex=""
      role="dialog"
      aria-labelledby="modalLabel"
      v-bind:style="{ display: confirm ? 'block' : 'none' }"
      aria-hidden="true"
    >
      <div class="w-100 h-100 d-flex">
        <div class="modal__dialog modal__dialog_alert" role="document">
          <div class="modal__header mb-3">
            <div class="d-flex align-items-center justify-content-between">
              <span class="modal__header__title text--capital">
                confirmation
              </span>
              <a
                class="
                  modal__close modal__close--white
                  d-flex
                  align-items-center
                  justify-content-between
                "
                @click="confirmSave()"
              >
                <span class="text--capital text--regular"> close </span>
                <ion-icon name="close-outline"></ion-icon>
              </a>
            </div>
            <span class="modal__header__subtitle text--capital">
              For Menu
            </span>
          </div>
          <div class="modal__content">
            <div class="modal__body">
              <h5 class="modal__head__title text--capital mb-3">
                Are you sure you want to proceed?
              </h5>
              <p class="form__label"></p>
              <div class="d-flex mt-5">
                <button
                  data-dismiss="#confirmModal"
                  class="
                    button button--lg
                    modal__confirm__button modal__confirm__button--cancel
                    mr-2
                  "
                  @click="confirmSave()"
                >
                  cancel
                </button>
                <button
                  data-dismiss="#confirmModal"
                  class="
                    button button--lg
                    modal__confirm__button modal__confirm__button--delete
                    ml-2
                    save-changes
                  "
                  @click="save()"
                >
                  {{ process ? "Saving..." : "Save" }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--CREATE MENU MODAL-->
    <div class="modal__dialog" role="document">
      <div class="modal__content">
        <div class="modal__head">
          <div class="modal__head__icon">
            <a class="modal__close modal__close--black" @click="$emit('close')">
              <ion-icon name="arrow-back-outline"></ion-icon>
            </a>
          </div>
          <h5 class="modal__head__title text--capital">Edit Menu</h5>
        </div>
        <div class="modal__body">
          <form>
            <div class="row">
              <div class="col-6">
                <div class="form__item">
                  <label class="form__label">Name</label>
                  <input
                    type="text"
                    class="input form__input form__input--lg"
                    placeholder="Enter Display Name"
                    v-model="menuName"
                  />
                </div>
              </div>

              <div class="col-6">
                <div class="form__item">
                  <label class="form__label">Type</label>

                  <select class="select select--lg" v-model="menuType">
                    <option value="null" disabled selected v-if="!loading">
                      Select Type
                    </option>
                    <option value="null" disabled selected v-else>
                      Loading...
                    </option>
                    <template v-if="!loading">
                      <option
                        v-for="(item, index) in menu"
                        :key="index"
                        v-bind:value="index"
                      >
                        {{ item }}
                      </option>
                    </template>
                  </select>
                </div>
              </div>
            </div>

            <div class="modal__accordion">
              <li
                data-toggle="collapse"
                class="sidebar-right__item modal__accordion--toggle"
                data-target="#userRights"
                aria-expanded="false"
                aria-controls="userRights"
              >
                <h5
                  class="
                    w-100
                    mb-0
                    d-flex
                    align-items-center
                    justify-content-between
                  "
                >
                  user rights
                  <ion-icon name="chevron-down-outline"></ion-icon>
                </h5>
              </li>
              <div id="userRights" class="collapse" data-parent="#accordion">
                <div class="pt-5">

                  <div class="row mb-5">
                    <div class="col-6">
                      <div class="form__item form__item--label">
                        <label
                          for="branch"
                          class="
                            has-checkbox
                            form__checkbox
                            text--dark text--capital
                          "
                          >Accessible by all Users
                          <input type="checkbox" id="branch" class="form-" v-model="accessByAllUsers" @change="unselectUserRights" />
                          <span class="checkmark"></span>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div 
                      class="col-6"
                      v-for="role in viewUsageRightsKeyNew"
                      :key="role.roleKey"
                    >
                      <div class="form__item form__item--label">
                        <label
                          :for="`users user-${role.roleKey}`"
                          class="
                            has-checkbox
                            form__checkbox
                            text--dark text--capital
                          "
                          >
                          {{ role.roleName }}
                          <input
                            type="checkbox"
                            :id="`users user-${role.roleKey}`"
                            class="form-"
                            v-model="role.isChecked" 
                            :disabled="accessByAllUsers"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </div>
                    </div>
                  </div>

                  <!-- <div class="row">
                    <div class="col-6">
                      <div class="form__item form__item--label">
                        <label
                          for="users"
                          class="
                            has-checkbox
                            form__checkbox
                            text--dark text--capital
                          "
                          >all users
                          <input type="checkbox" id="users" class="form-" />
                          <span class="checkmark"></span>
                        </label>
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="form__item form__item--label">
                        <label
                          for="api"
                          class="
                            has-checkbox
                            form__checkbox
                            text--dark text--capital
                          "
                          >all api
                          <input type="checkbox" id="api" class="form-" />
                          <span class="checkmark"></span>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-6">
                      <div class="form__item form__item--label">
                        <label
                          for="branch"
                          class="
                            has-checkbox
                            form__checkbox
                            text--dark text--capital
                          "
                          >branch advisor
                          <input type="checkbox" id="branch" class="form-" />
                          <span class="checkmark"></span>
                        </label>
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="form__item form__item--label">
                        <label
                          for="cfo"
                          class="
                            has-checkbox
                            form__checkbox
                            text--dark text--capital
                          "
                          >chief financial officer
                          <input type="checkbox" id="cfo" class="form-" />
                          <span class="checkmark"></span>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-6">
                      <div class="form__item form__item--label">
                        <label
                          for="collectionlead"
                          class="
                            has-checkbox
                            form__checkbox
                            text--dark text--capital
                          "
                          >collection and recovery team
                          <input
                            type="checkbox"
                            id="collectionlead"
                            class="form-"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="form__item form__item--label">
                        <label
                          for="collection"
                          class="
                            has-checkbox
                            form__checkbox
                            text--dark text--capital
                          "
                          >collection and recovery team
                          <input
                            type="checkbox"
                            id="collection"
                            class="form-"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-6">
                      <div class="form__item form__item--label">
                        <label
                          for="contact"
                          class="
                            has-checkbox
                            form__checkbox
                            text--dark text--capital
                          "
                          >contact center
                          <input type="checkbox" id="contact" class="form-" />
                          <span class="checkmark"></span>
                        </label>
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="form__item form__item--label">
                        <label
                          for="customer"
                          class="
                            has-checkbox
                            form__checkbox
                            text--dark text--capital
                          "
                          >customer engagement
                          <input type="checkbox" id="customer" class="form-" />
                          <span class="checkmark"></span>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-6">
                      <div class="form__item form__item--label">
                        <label
                          for="developer"
                          class="
                            has-checkbox
                            form__checkbox
                            text--dark text--capital
                          "
                          >developer
                          <input type="checkbox" id="developer" class="form-" />
                          <span class="checkmark"></span>
                        </label>
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="form__item form__item--label">
                        <label
                          for="finance"
                          class="
                            has-checkbox
                            form__checkbox
                            text--dark text--capital
                          "
                          >finance
                          <input type="checkbox" id="finance" class="form-" />
                          <span class="checkmark"></span>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-6">
                      <div class="form__item form__item--label">
                        <label
                          for="operations"
                          class="
                            has-checkbox
                            form__checkbox
                            text--dark text--capital
                          "
                          >head of operations
                          <input
                            type="checkbox"
                            id="operations"
                            class="form-"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="form__item form__item--label">
                        <label
                          for="sales"
                          class="
                            has-checkbox
                            form__checkbox
                            text--dark text--capital
                          "
                          >head of sales
                          <input type="checkbox" id="sales" class="form-" />
                          <span class="checkmark"></span>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-6">
                      <div class="form__item form__item--label">
                        <label
                          for="human"
                          class="
                            has-checkbox
                            form__checkbox
                            text--dark text--capital
                          "
                          >human resources
                          <input type="checkbox" id="human" class="form-" />
                          <span class="checkmark"></span>
                        </label>
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="form__item form__item--label">
                        <label
                          for="managing"
                          class="
                            has-checkbox
                            form__checkbox
                            text--dark text--capital
                          "
                          >managing director
                          <input type="checkbox" id="managing" class="form-" />
                          <span class="checkmark"></span>
                        </label>
                      </div>
                    </div>
                  </div> -->
                </div>
              </div>
            </div>

            <div class="form__item mt-4">
              <div class="form__action">
                <button
                  id="save-changes"
                  type="button"
                  class="button form__button form__button--lg"
                  @click="confirmSave()"
                >
                  {{ process ? "Saving..." : "save changes" }}
                </button>
                <a
                  role="button"
                  class="
                    modal__close
                    form__action__text form__action__text--danger
                    text--capital
                    ml6
                  "
                  @click="$emit('close')"
                >
                  cancel
                </a>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import { mapState } from "vuex";
import ApiService from "@/core/services/general.service";
import { GET_MENU } from "@/core/services/store/types";
export default {
  name: "EditParentMenu",
  components: {},
  props: {
    show: Boolean,
    close: Function,
    singleMenu: Object,
    allRoles: Array,
  },
  data() {
    return {
      menuParent: {
        menuName: this.singleMenu.menuName,
        menuType: this.singleMenu.customMenuKey,
      },
      userKey: "a2f3c50683c54b4aa69ac637eb66a8b6",
      // viewUsageRightsKey: this.singleMenu.viewUsageRightsKey,
      viewUsageRightsKey: [],
      viewUsageRightsKeyNew: [],
      accessByAllUsers: this.singleMenu.accessByAllUsers,
      confirm: false,
      process: false,
    };
  },
  methods: {
    getMenu() {
      this.$store.dispatch(GET_MENU);
    },
    resetWindow: function () {
      // Object.assign(this.$data, initialState());
    },
    confirmSave() {
      if (!this.confirm) {
        this.confirm = true;
      } else {
        this.confirm = false;
      }
    },
    getNewRoles() {
      // var newRolesStringed = JSON.stringify(this.singleMenu.viewUsageRightsKey);
      // var result = this.allRoles.map((item) => {
      //   let newRoles = JSON.stringify(item);
      //   let regex = new RegExp(newRoles, 'g');
      //   if(regex.test(newRolesStringed)) return Object.assign({}, item, { isChecked: true });
      //   return Object.assign({}, item, { isChecked: false });
      // });

      const allNewRoles = this.allRoles.map((item) => {
        return {
          ...item,
          isChecked: false
        };
      });

      const result = this.singleMenu.viewUsageRightsKey;

      if (result !== undefined) {
        result.forEach((b)=>{
          allNewRoles.forEach((a)=>{
            if(b.roleKey == a.roleKey) {
              a.isChecked = true;
            }
          })
        });
      }

      this.viewUsageRightsKeyNew = allNewRoles;
    },

    unselectUserRights() {
      this.viewUsageRightsKeyNew = this.viewUsageRightsKeyNew.map((item) => {
        return {
          ...item,
          isChecked: false
        };
      });
    },

    save: function () {
      this.process = true;

      let copyOfPayload = Object.assign(this.$data);
      let newPayload = {
        ...copyOfPayload,
        menuName: copyOfPayload.menuParent.menuName,
        menuTypekey: copyOfPayload.menuParent.menuType,
        customMenuKey: this.singleMenu.customMenuKey,
        viewUsageRightsKey: this.viewUsageRightsKeyNew.filter(item => item.isChecked).map(item => item.roleKey)
      };
      delete newPayload.menuParent;
      delete newPayload.confirm;
      delete newPayload.process;
      delete newPayload.viewUsageRightsKeyNew;

      ApiService.put(
        `CustomMenu/${this.singleMenu.customMenuKey}`,
        newPayload
      )
        .then((res) => res.data)
        .then((res) => {
          this.confirm = false;
          this.process = false;
          if (res.status) {
            this.$emit("alertSuccess", res.message);
            // this.resetWindow();
            this.$emit("close");
            this.$emit("reload");
          } else {
            this.$emit("alertError", res.message);
          }
        })
        .catch((e) => {
          this.confirm = false;
          try {
            this.process = false;
            this.$emit("alertError", e.response.data.message);
          } catch (e) {
            this.$emit(
              "alertError",
              "An unexpected Error occurred: Kindly confirm you are connected to the internet"
            );
          }
        });
    },
  },
  watch: {
    singleMenu: function () {
      //This is done because at the time of rendering the data in the props may not be available
      //Hence I watch and update it once it is ready
      this.menuParent.menuName = this.singleMenu.menuName;
      this.menuParent.menuType = this.singleMenu.customMenuKey;
      this.getNewRoles();
    },
  },
  computed: {
    enums() {
      return this.$store.state.loan.enums;
    },
    loading() {
      return this.$store.state.loan.loading_all;
    },
    menu() {
      return this.$store.state.views.menu.data
        ? this.$store.state.views.menu.data
        : [];
    },
    saving() {
      return this.$store.state.loan.saving;
    },
    menuName: {
      set(val) {
        this.menuParent.menuName = val;
      },
      get() {
        return this.menuParent.menuName;
      },
    },
    allNewRoles() {
      return this.allRoles.map((item) => {
        return {
          ...item,
          isChecked: false
        };
      })
    },
    menuType: {
      set(val) {
        this.menuParent.menuType = val;
      },
      get() {
        return this.menuParent.menuType;
      },
    },
  },
  mounted() {
    this.getMenu();
  },
};
</script>
