<template>
  <div
    v-if="show"
    ref="edit-view-modal"
    class="modal modal__right"
    :class="{ show: show === true }"
    :style="{ display: 'block' }"
    id="addModal"
    tabindex=""
    role="dialog"
    aria-labelledby="modalLabel"
    aria-hidden="true"
  >
    <!--CONFIRM DIALOG-->
    <div
      class="modal modal__confirm show"
      id="confirmModal"
      tabindex=""
      role="dialog"
      aria-labelledby="modalLabel"
      v-bind:style="{ display: confirm ? 'block' : 'none' }"
      aria-hidden="true"
    >
      <div class="w-100 h-100 d-flex">
        <div class="modal__dialog modal__dialog_alert" role="document">
          <div class="modal__header mb-3">
            <div class="d-flex align-items-center justify-content-between">
              <span class="modal__header__title text--capital">
                confirmation
              </span>
              <a
                class="
                  modal__close modal__close--white
                  d-flex
                  align-items-center
                  justify-content-between
                "
                @click="confirmSave()"
              >
                <span class="text--capital text--regular"> close </span>
                <ion-icon name="close-outline"></ion-icon>
              </a>
            </div>
            <span class="modal__header__subtitle text--capital">
              For Views
            </span>
          </div>
          <div class="modal__content">
            <div class="modal__body">
              <h5 class="modal__head__title text--capital mb-3">
                Are you sure you want to proceed?
              </h5>
              <p class="form__label"></p>
              <div class="d-flex mt-5">
                <button
                  data-dismiss="#confirmModal"
                  class="
                    button button--lg
                    modal__confirm__button modal__confirm__button--cancel
                    mr-2
                  "
                  @click="confirmSave()"
                >
                  cancel
                </button>
                <button
                  data-dismiss="#confirmModal"
                  class="
                    button button--lg
                    modal__confirm__button modal__confirm__button--delete
                    ml-2
                    save-changes
                  "
                  @click="save()"
                >
                  {{ process ? "Saving..." : "Save" }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--EDIT VIEW MODAL-->
    <div class="modal__dialog" role="document">
      <div class="modal__content">
        <div class="modal__head">
          <div class="modal__head__icon">
            <a
              data-dismiss="#addModal"
              class="modal__close modal__close--black"
              @click="$emit('close')"
            >
              <ion-icon name="arrow-back-outline"></ion-icon>
            </a>
          </div>
          <h5 class="modal__head__title text--capital">Edit View</h5>
        </div>
        <div class="modal__body">
          <form>
            <div class="row align-items-center">
              <div class="col-6">
                <div class="form__item">
                  <label class="form__label">first name</label>
                  <input
                    type="text"
                    class="input form__input form__input--lg"
                    placeholder="Enter first name"
                    v-model="name"
                  />
                </div>
              </div>
              <div class="col-6">
                <div class="form__item">
                  <p class="text--regular text--grey form__item_text">
                    Custom Field Type
                  </p>
                  <p class="text--regular text--black form__item_text">
                    {{ singleMenu.menuName }}
                  </p>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-6">
                <div class="form__item">
                  <label class="form__label">Default View Display</label>
                  <select
                    required
                    class="select select--sm text--capital"
                    v-model="viewType"
                  >
                    <option value="null" disabled selected v-if="!loading">
                      Select Default View Display
                    </option>
                    <option value="null" disabled selected v-else>
                      Loading...
                    </option>
                    <template v-if="!loading">
                      <option
                        v-for="item in menuViewMode"
                        :key="item"
                        v-bind:value="item"
                      >
                        {{ item }}
                      </option>
                    </template>
                  </select>
                </div>
              </div>
            </div>

            <ul id="accordion">
              <div class="modal__accordion">
                <li
                  data-toggle="collapse"
                  class="sidebar-right__item modal__accordion--toggle"
                  data-target="#filter"
                  aria-expanded="false"
                  aria-controls="filter"
                >
                  <h5
                    class="
                      w-100
                      mb-0
                      d-flex
                      align-items-center
                      justify-content-between
                    "
                  >
                    filter
                    <ion-icon name="chevron-down-outline"></ion-icon>
                  </h5>
                </li>
                <div id="filter" class="collapse" data-parent="#accordion">
                  <div class="pt-5">
                    <div
                      class="row align-items-end"
                      v-for="(itemTop, indexTop) in filter"
                      :key="indexTop"
                    >
                      <div class="col-3">
                        <div class="form__item">
                          <label
                            class="form__label text--black text--capital mb-3"
                            >{{ indexTop === 0 ? "Where" : match }}</label
                          >
                          <select
                            required
                            class="select select--lg text--capital"
                            v-model="itemTop.dummyCol"
                            @change="onChange($event)"
                          >
                            <option
                              value="null"
                              disabled
                              selected
                              v-if="!loading"
                            >
                              Select Type
                            </option>
                            <option value="null" disabled selected v-else>
                              Loading...
                            </option>
                            <template v-if="!loading">
                              <option
                                v-for="(item, index) in modelProperty"
                                :key="index"
                                v-bind:value="index"
                                v-bind:data-value="indexTop + ',' + index"
                              >
                                {{ item.plainName }}
                              </option>
                            </template>
                          </select>
                        </div>
                      </div>

                      <div class="col-3" v-if="itemTop.showOp">
                        <div class="form__item">
                          <select
                            required
                            class="select select--lg text--capital"
                            v-model="itemTop.operator"
                            @change="onChange2($event)"
                          >
                            <template>
                              <option
                                v-for="(index, item) in modelProperty[
                                  itemTop.operatorKey
                                ].operations"
                                :key="item"
                                v-bind:value="item"
                                v-bind:data-value="indexTop + ',' + index"
                              >
                                {{ item }}
                              </option>
                            </template>
                          </select>
                        </div>
                      </div>

                      <div
                        class="col-4"
                        v-if="
                          itemTop.values[0] &&
                          itemTop.values[0].value != null &&
                          filter[indexTop].dataType !== 'System.DateTime'
                        "
                      >
                        <div class="form__item">
                          <input
                            type="text"
                            class="input form__input form__input--lg"
                            placeholder="Enter value"
                            v-model="itemTop.values[0].value"
                          />
                        </div>
                      </div>

                      <div
                        class="col-2"
                        v-if="
                          itemTop.values[0] &&
                          itemTop.values[0].value != null &&
                          filter[indexTop].dataType == 'System.DateTime'
                        "
                      >
                        <datepicker
                          input-class="input form__input form__input--lg"
                          style="top: -40px"
                          v-model="itemTop.values[0].value"
                        ></datepicker>
                      </div>

                      <div
                        class="col-2"
                        v-if="
                          itemTop.values[1] &&
                          itemTop.values[1].value != null &&
                          filter[indexTop].dataType !== 'System.DateTime'
                        "
                      >
                        <div class="form__item">
                          <input
                            type="text"
                            class="input form__input form__input--lg"
                            placeholder="Enter value"
                            v-model="itemTop.values[1].value"
                          />
                        </div>
                      </div>

                      <div
                        class="col-2"
                        v-if="
                          itemTop.values[1] &&
                          itemTop.values[1].value != null &&
                          filter[indexTop].dataType == 'System.DateTime'
                        "
                      >
                        <datepicker
                          input-class="input form__input form__input--lg"
                          style="top: -40px"
                          v-model="itemTop.values[1].value"
                        ></datepicker>
                      </div>

                      <div class="col-2">
                        <div class="form__item">
                          <button
                            @click="deleteFilter(indexTop)"
                            class="
                              button
                              page__body__header__button
                              modal__confirm__button--delete
                              button--sm
                              text--black
                              w-100
                              text--capital
                            "
                          >
                            <span>X</span>
                          </button>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <button
                        type="button"
                        class="
                          button
                          page__body__header__button
                          button--grey
                          text--capital
                        "
                        @click="addFilter()"
                      >
                        <span class="d-inline-flex mr-2">add new filter</span>
                        <ion-icon
                          name="add-outline"
                          class="text--medium"
                        ></ion-icon>
                      </button>
                      <div class="col col-5">
                        <select
                          required
                          class="select select--sm text--capital"
                          v-model="match"
                        >
                          <option
                            value="null"
                            disabled
                            selected
                            v-if="!loading"
                          >
                            Select Operator
                          </option>
                          <option value="null" disabled selected v-else>
                            Loading...
                          </option>
                          <template v-if="!loading">
                            <option
                              v-for="item in linkingOperator"
                              :key="item"
                              v-bind:value="item"
                            >
                              {{ item }}
                            </option>
                          </template>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="modal__accordion">
                <li
                  data-toggle="collapse"
                  class="sidebar-right__item modal__accordion--toggle"
                  data-target="#fields"
                  aria-expanded="false"
                  aria-controls="fields"
                >
                  <h5
                    class="
                      w-100
                      mb-0
                      d-flex
                      align-items-center
                      justify-content-between
                    "
                  >
                    fields
                    <ion-icon name="chevron-down-outline"></ion-icon>
                  </h5>
                </li>
                <div id="fields" class="collapse" data-parent="#accordion">
                  <div class="pt-5">
                    <div class="form__item">
                      <label class="form__label text--black text--capital mb-3"
                        >Available Columns</label
                      >
                      <select class="multisselect" multiple v-if="hasUpdated">
                        <template v-for="item in modelProperty">
                          <option
                            v-if="checkSel(item) == true"
                            selected="selected"
                            :value="`${item.column},${item.entity}`"
                            :key="item.plainName"
                          >
                            {{ item.plainName }}
                          </option>
                          <option
                            v-else
                            :value="`${item.column},${item.entity}`"
                            :key="item.plainName"
                          >
                            {{ item.plainName }}
                          </option>
                        </template>
                      </select>
                    </div>
                    <div class="form__item" id="branch-area"></div>

                    <div class="row align-items-end">
                      <div class="col-8">
                        <div class="form__item">
                          <label
                            class="form__label text--black text--capital mb-3"
                            >sort by</label
                          >
                          <select
                            required
                            class="select select--lg"
                            v-model="sortBy.index"
                            @change="onChange4($event)"
                          >
                            <option
                              value="null"
                              disabled
                              selected
                              v-if="!loading"
                            >
                              sort by
                            </option>
                            <option value="null" disabled selected v-else>
                              Loading...
                            </option>
                            <template v-if="!loading">
                              <option
                                v-for="(item, index) in modelProperty"
                                :key="index"
                                v-bind:value="index"
                                v-bind:data-value="index"
                              >
                                {{ item.plainName }}
                              </option>
                            </template>
                          </select>
                        </div>
                      </div>
                      <div class="col-4">
                        <div class="form__item">
                          <select
                            required
                            class="select select--sm text--capital"
                            v-model="sortBy.sortOrder"
                          >
                            <option
                              value="null"
                              disabled
                              selected
                              v-if="!loading"
                            >
                              Select type
                            </option>
                            <option value="null" disabled selected v-else>
                              Loading...
                            </option>
                            <template v-if="!loading">
                              <option
                                v-for="item in sortingValue"
                                :key="item"
                                v-bind:value="item"
                              >
                                {{ item }}
                              </option>
                            </template>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div class="d-flex">
                      <label
                        for="IncludeTotals"
                        class="
                          has-checkbox
                          page__body__header__label
                          text--black text--capital
                          mb-3
                          mr-3
                        "
                        >Include Totals
                        <input
                          type="checkbox"
                          id="IncludeTotals"
                          class="form-"
                          v-model="includeTotals"
                        />
                        <span class="checkmark"></span>
                      </label>
                      <label
                        for="timeStamp"
                        class="
                          has-checkbox
                          page__body__header__label
                          text--black text--capital
                          mb-3
                        "
                        >Include Time stamp
                        <input
                          type="checkbox"
                          id="timeStamp"
                          class="form-"
                          v-model="includeTimestamps"
                        />
                        <span class="checkmark"></span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <div class="modal__accordion">
                <li
                  data-toggle="collapse"
                  class="sidebar-right__item modal__accordion--toggle"
                  data-target="#userRights"
                  aria-expanded="false"
                  aria-controls="userRights"
                >
                  <h5
                    class="
                      w-100
                      mb-0
                      d-flex
                      align-items-center
                      justify-content-between
                    "
                  >
                    user rights
                    <ion-icon name="chevron-down-outline"></ion-icon>
                  </h5>
                </li>
                <div id="userRights" class="collapse" data-parent="#accordion">
                  <div class="pt-5">
                    <div class="row mb-5">
                    <div class="col-6">
                      <div class="form__item form__item--label">
                        <label
                          for="branch"
                          class="
                            has-checkbox
                            form__checkbox
                            text--dark text--capital
                          "
                          >Accessible by all Users
                          <input type="checkbox" id="branch" class="form-" v-model="accessByAllUsers" @change="unselectUserRights" />
                          <span class="checkmark"></span>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div 
                      class="col-6"
                      v-for="role in viewUsageRightsKeyNew"
                      :key="role.roleKey"
                    >
                      <div class="form__item form__item--label">
                        <label
                          :for="`users user-${role.roleKey}`"
                          class="
                            has-checkbox
                            form__checkbox
                            text--dark text--capital
                          "
                          >
                          {{ role.roleName }}
                          <input
                            type="checkbox"
                            :id="`users user-${role.roleKey}`"
                            class="form-"
                            v-model="role.isChecked" 
                            :disabled="accessByAllUsers"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </div>
                    </div>
                  </div>
                  </div>
                </div>
              </div>
            </ul>
            <div class="form__item">
              <div class="form__action">
                <button
                  id="save-changes"
                  type="button"
                  class="button form__button form__button--lg"
                  @click="confirmSave()"
                >
                  {{ process ? "Saving..." : "save changes" }}
                </button>
                <a
                  role="button"
                  class="
                    modal__close
                    form__action__text form__action__text--danger
                    text--capital
                    ml6
                  "
                  @click="$emit('close')"
                >
                  cancel
                </a>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import ApiService from "@/core/services/general.service";
import { GET_MENU } from "@/core/services/store/types";
import Datepicker from "vuejs-datepicker";
export default {
  name: "EditView",
  components: {
    Datepicker,
  },
  props: {
    show: Boolean,
    close: Function,
    singleMenu: Object,
    singleView: Object,
    modelProperty: Array,
    linkingOperator: Array,
    sortingValue: Array,
    menuViewMode: Array,
    allRoles: Array,
  },
  data: function () {
    return {
      name: "",
      userKey: "a2f3c50683c54b4aa69ac637eb66a8b6",
      usageRightKey: "3da140d5c0994171a49b91344df8d090",
      customMenuKey: this.singleMenu.customMenuKey,
      customViewKey: this.singleView.customViewKey,
      viewUsageRightsKey: [],
      viewUsageRightsKeyNew: [],
      viewType: (this.menuViewMode && this.menuViewMode[0]) || null,
      includeTotals: true,
      accessByAllUsers: this.singleView?.accessByAllUsers,
      includeTimestamps: true,
      filter: [
        {
          key: "",
          showOp: false,
          operatorKey: 0,
          dummyCol: "",
          columnName: "",
          entityName: "",
          operator: "",
          values: [{ value: "" }],
          dataType: "",
        },
      ],
      match: (this.linkingOperator && this.linkingOperator[0]) || null,
      columns: [
        {
          key: "", //empty
          columnName: "",
          entityName: "",
          plainName: "",
        },
      ],
      colVal: [],
      sortBy: {
        key: "", //empty
        index: 0,
        columnName: "",
        entityName: "",
        sortOrder: (this.sortingValue && this.sortingValue[0]) || null,
      },
      confirm: false,
      process: false,
      hasUpdated: false,
    };
  },
  methods: {
    checkSel(e) {
      let val = `${e.column},${e.entity}`;
      if (this.colVal.includes(val)) {
        return true;
      } else {
        return false;
      }
    },
    transformCol(arr) {
      let rel = arr.map((e) => `${e.columnName},${e.entityName}`);
      return rel;
    },

    getNewRoles() {
      const allNewRoles = this.allRoles.map((item) => {
        return {
          ...item,
          isChecked: false
        };
      });

      const result = this.singleView?.viewUsageRightsKey;

      result.forEach((b)=>{
        allNewRoles.forEach((a)=>{
          if(b.roleKey == a.roleKey) {
            a.isChecked = true;
          }
        })
      });

      this.viewUsageRightsKeyNew = allNewRoles;
    },

    unselectUserRights() {
      this.viewUsageRightsKeyNew = this.viewUsageRightsKeyNew.map((item) => {
        return {
          ...item,
          isChecked: false
        };
      });
    },

    transformFilter(arr) {
      let rel = arr.map((e) => {
        return {
          ...e,
          showOp: true,
          operatorKey: this.modelProperty.findIndex(
            (a) => a.column === e.columnName
          ),
          operator: e.operator,
          values: e.values.map((x) => ({ value: x })),
          dummyCol: this.modelProperty.findIndex(
            (a) => a.column === e.columnName
          ),
        };
      });
      return rel;
    },
    transformFilterVal(arr) {
      let rel = arr.map((e) => {
        return {
          ...e,
          values: e.values.map((x) => x.value),
        };
      });
      return rel;
    },
    getMenu() {
      this.$store.dispatch(GET_MENU);
    },
    onChange(e) {
      const theTarget =
        e.target.options[e.target.options.selectedIndex].dataset;
      let [top, child] = theTarget.value.split(",");
      this.filter[top].columnName = this.modelProperty[child].column;
      this.filter[top].dataType = this.modelProperty[child].dateType;
      this.filter[top].entityName = this.modelProperty[child].entity;
      this.filter[top].showOp = true;
      this.filter[top].operatorKey = Number(child);
      this.filter[top].operator = Object.keys(
        this.modelProperty[child].operations
      )[0];
      this.filter[top].dummyCol = e.target.value;
      let loopValue =
        this.modelProperty[child].operations[
          Object.keys(this.modelProperty[child].operations)[0]
        ];

      for (let i = 0; i < Number(loopValue); i++) {
        if (this.modelProperty[child].dataType == "System.DateTime") {
          this.filter[top].values[i].value = "01-01-2020";
        } else {
          this.filter[top].values[i] = { value: "" };
        }
      }

      if (Number(loopValue) === 0) {
        this.filter[top].values = [{ value: null }];
      }
      if (Number(loopValue) === 1) {
        this.filter[top].values = [{ value: this.filter[top].values[0].value }];
      }
    },
    onChange2(e) {
      const theTarget =
        e.target.options[e.target.options.selectedIndex].dataset;
      let [top, loopValue] = theTarget.value.split(",");
      let child = this.filter[top].dummyCol;

      for (let i = 0; i < Number(loopValue); i++) {
        if (this.modelProperty[child].dataType == "System.DateTime") {
          this.filter[top].values[i].value = "01-01-2020";
        } else {
          this.filter[top].values[i] = { value: "" };
        }
      }

      if (Number(loopValue) === 0) {
        this.filter[top].values = [{ value: null }];
      }
      if (Number(loopValue) === 1) {
        this.filter[top].values = [{ value: this.filter[top].values[0].value }];
      }
    },
    onChange4(e) {
      const val = e.target.value;
      this.sortBy.columnName = this.modelProperty[val].column;
      this.sortBy.entityName = this.modelProperty[val].entity;
      this.sortBy.index = val;
    },
    addFilter() {
      this.filter.push({
        key: "", //empty
        showOp: false,
        operatorKey: 0,
        dummyCol: "",
        columnName: "",
        entityName: "",
        operator: "",
        values: [{ value: "" }],
        //or || and
        dataType: "",
      });
    },
    deleteFilter(e) {
      if (this.filter.length == 1) {
        this.$emit("alertError", "At least one filter must be present");
        return;
      }
      this.filter.splice(e, 1);
    },
    confirmSave() {
      if (!this.confirm) {
        this.confirm = true;
      } else {
        this.confirm = false;
      }
    },
    validate() {
      if (this.name == "") {
        this.$emit("alertError", "You must provide a name!");
        return false;
      }

      if (this.sortBy.columnName == "" || this.sortBy.entityName == "") {
        this.$emit("alertError", "You must select a sortBy value!");
        return false;
      }

      return true;
    },
    save: function () {
      if (!this.validate()) return;
      this.process = true;
      let copyOfPayload = { ...this.$data };
      copyOfPayload.columns = [];
      this.colVal.map((e, i) => {
        copyOfPayload.columns[i] = {
          key: e.split(",")[2] || "",
          columnName: e.split(",")[0],
          entityName: e.split(",")[1],
        };
      });
      copyOfPayload.filter = this.transformFilterVal(copyOfPayload.filter);
      let newPayload = {
        ...copyOfPayload,
        customMenuKey: this.singleMenu.customMenuKey,
        viewUsageRightsKey: this.viewUsageRightsKeyNew.filter(item => item.isChecked).map(item => item.roleKey)
      };
      ApiService.put(
        `CustomView/${this.customViewKey}`,
        newPayload
      )
        .then((res) => res.data)
        .then((res) => {
          this.confirm = false;
          this.process = false;
          if (res.status) {
            this.$emit("alertSuccess", res.message);
            this.$emit("close");
            this.$emit("reload");
          } else {
            this.$emit("alertError", res.message);
          }
        })
        .catch((e) => {
          this.confirm = false;
          try {
            this.process = false;
            this.$emit("alertError", e.response.data.message);
          } catch (e) {
            this.$emit(
              "alertError",
              "An unexpected Error occurred: Kindly confirm you are connected to the internet"
            );
          }
        });
    },
  },
  watch: {
    linkingOperator: function () {
      this.match = this.linkingOperator && this.linkingOperator[0];
    },
    menuViewMode: function () {
      this.viewType = this.menuViewMode && this.menuViewMode[0];
    },
    sortingValue: function () {
      if (this.sortBy)
        this.sortBy.sortOrder = this.sortingValue && this.sortingValue[0];
    },
    singleMenu: function () {
      this.customMenuKey = this.singleMenu?.customMenuKey || null;
    },
    singleView(nextProps) {
      this.getNewRoles();
      const ob = { ...this.$data };
      var self = this;
      Object.keys(nextProps).map(function (key) {
        if (ob.hasOwnProperty(key) && nextProps.hasOwnProperty(key)) {
          if (key === "filter") {
            self[key] = self.transformFilter(nextProps[key]);
            return;
          }

          if (key === "columns") {
            self.colVal = self.transformCol(nextProps[key]);
            self.columns = self.transformCol(nextProps[key]);
            self.hasUpdated = true;
            return;
          }

          if (key === "sortBy") {
            self.sortBy = nextProps[key];
            self.sortBy.index = self.modelProperty.findIndex(
              (x) => x.column === nextProps[key].columnName
            );
            return;
          }

          self[key] = nextProps[key];
        }
      });
    },
  },
  computed: mapState({
    enums: (state) => state.loan.enums,
    loading: (state) => state.loan.loading_all,
    menu: (state) => (state.views.menu.data ? state.views.menu.data : []),
  }),
  mounted() {
    this.getMenu();
    const self = this;
    setTimeout(function () {
      window.$(".multisselect").selectize({
        plugins: ["remove_button", "drag_drop"],
        onChange: function (value) {
          if (value == null) {
            self.colVal = [];
            return;
          }
          self.colVal = value;
        },
      });
    }, 2000);
  },
};
</script>
